<template>
    <div>
   <ClientReferalDesk v-if="$vuetify.breakpoint.width > 600" :RefferalCode="RefferalCode" @gencode="GenerateCode" :Referral="Referral"></ClientReferalDesk>
   <ClientReferalMobile v-else :RefferalCode="RefferalCode" @gencode="GenerateCode" :Referral="Referral"></ClientReferalMobile>

    </div>
</template>
<script>
import ClientReferalMobile from './ClientReferalComponents/ClientReferalMobile.vue';
import ClientReferalDesk from './ClientReferalComponents/ClientReferalDesk.vue';
import EventServices from '@/services/EventServices';
export default {
    components:{
        ClientReferalDesk,ClientReferalMobile
    },
    data(){
        return{
            
            Referral:{},
            ClientId:localStorage.getItem("clientId"),
            RefferalCode:""
        }
    },
    methods:{
        getClientReferralList() {
  this.$globalData.overlay = true;
  
  EventServices.FetchClientReferrals(this.ClientId)
    .then((response) => {
      if (response?.data?.status === "S") {
        console.log(response?.data,"dsfsdf");
        
        this.Referral = { ...response.data }; // Ensure 'this.Referral' is used for reactivity
      } else {
        this.loading = false;
        this.MessageBar("E", response?.data?.errMsg || "Unexpected error occurred.");
      }
    })
    .catch((error) => {
      this.MessageBar("E", error.message || "An error occurred.");
    })
    .finally(() => {
      this.$globalData.overlay = false;
    });
},

GenerateCode() {
  this.$globalData.overlay = true;
  
  EventServices.GenerateReferralCode(this.ClientId)
    .then((response) => {
      if (response?.data?.status === "S") {
        // this.RefferalCode = response.data.errMsg ; // Ensure 'this.Referral' is used for reactivity
        this.getClientReferralList()
      } else {
        this.MessageBar("E", response?.data?.errMsg || "Unexpected error occurred.");
      }
    })
    .catch((error) => {
      this.MessageBar("E", error.message || "An error occurred.");
    })
    .finally(() => {
      this.$globalData.overlay = false;
    });
}


    },
    mounted(){
        this.getClientReferralList()
    }
   

}
</script>