<template>
    <div>
<AdminReferalList></AdminReferalList>
    </div>
</template>
<script >
import AdminReferalList from '@/components/Referal/AdminReferalMain.vue';
export default{
    components:{
        AdminReferalList
    }
    
}
</script>