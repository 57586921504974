<template>
  <v-container>
    <DisplayCard v-if="userProfile != undefined" :userProfile="userProfile" @profilepic="uploadprofilepic" :clientprofile="clientprofile" :profileDetails="profileDetails"></DisplayCard>
    
    <v-divider class="primary"></v-divider>
    <v-tabs v-model="activeTab" left>
      <v-tab>My profile</v-tab>
      <!-- <v-tab>Soul preference</v-tab> -->
    </v-tabs>
    <v-window v-model="activeTab" class="elevation-0 mt-9" :touchless="true">
      <v-window-item v-for="(tab, index) in tabs" :key="index" >
       <myprofile @getUserProfile="$emit('getUserProfile')" :clientprofile="clientprofile" :profileDetails="profileDetails" :minDate="minDate" :maxDate="maxDate" :dropDownList="dropDownList" :NA="NA" :userProfile="userProfile" v-if="index == 0"></myprofile>
       <soulprofile :profileDetails="profileDetails" :NA="NA" :userProfile="userProfile" :clientprofile="clientprofile" v-else></soulprofile>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import soulprofile from './partnerDetails/partnerDetailsMain.vue'
import myprofile from './profileDetails/profileDetailsmain.vue'
import DisplayCard from './DisplayCard.vue'
import EventServices from '@/services/EventServices.js'
export default {
  components: {
    DisplayCard,myprofile,soulprofile
  },
  data() {
    return {
      activeTab:0,
      NA: "Not Mentioned",
      BtnText: "update",
      pic: null,
      DefaultProfile: {},
      tabs: ["My profile", "soul preference"]
    }
  },
  props: {
    userProfile: {}, dropDownList: {},clientprofile:Boolean,profileDetails:{}
  },
  computed: {

    minDate() {
      const min = new Date();
      min.setFullYear(min.getFullYear() - 18);
      return min.toISOString().substr(0, 10); // Format as YYYY-MM-DD
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10); // Format as YYYY-MM-DD
    }
  },
  methods: {

    uploadprofilepic(pic) {
      const formData = new FormData();
      formData.append('file', pic);
      this.$globalData.overlay = true
      EventServices.SetProfileImg(formData, this.$store.state.ClientId)
        .then((response) => {
          if (response.data.status == "S") {
            this.MessageBar("S", "Image updated Successfully")
          //   this.userProfile.userInfo.profilepic = response.data.objecturl
          //   this.$store.state.ProfilePic =  response.data.objecturl
          //  localStorage.setItem("ProfilePic", response.data.objecturl)
           this.userProfile.userInfo.profilepic = response.data.objecturl + "?t=" + new Date().getTime();
           this.$store.state.ProfilePic = response.data.objecturl + "?t=" + new Date().getTime();
           localStorage.setItem("ProfilePic", response.data.objecturl + "?t=" + new Date().getTime());
            // this.$emit('getUserProfile')
            // window.location.reload
            this.$globalData.overlay = false
          } else {
            this.loading = false
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })
        .catch((error) => {
          this.$globalData.overlay = false
          this.MessageBar("E", error);
        });
    },
    submitForm(Indicator) {
      // console.log('submitForm', this.userProfile, Indicator);
      this.$globalData.overlay = true
      EventServices.SetUserProfile(this.userProfile, Indicator)
        .then((response) => {
          if (response.data.status == "S") {
            this.MessageBar("S", response.data.errMsg)
            this.basicInfoEdit = false
            this.profileHeaderEdit = false
            this.LocationCarrersEdit = false
            this.ReligiousBackgroundEdit = false
            this.PartnerBasicInfoEdit = false
            this.PartnerLocationCarrersEdit = false
            this.ProfileAbout = false
            this.$emit('getUserProfile')
            this.$globalData.overlay = false

            this.loading = false
          } else {
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })
        .catch((error) => {
          this.$globalData.overlay = false
          this.MessageBar("E", error);
        });
      // console.log(UserDetails,"submitForm");
    }
  },
  mounted() {
    this.DefaultProfile = { ...this.userProfile }
  }
}
</script>