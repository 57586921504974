<template>
    <div>
        <v-container>
            <v-slide-x-transition mode="out-in" appear>
                <ClientReferalList></ClientReferalList>
            </v-slide-x-transition>
        </v-container>
    </div>
</template>
<script>
import ClientReferalList from '@/components/Referal/ClientReferalMain.vue';
export default {
    components: {
        ClientReferalList
    }
}
</script>