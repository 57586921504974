<template>
    <div>
      <v-card class="elevation-0 ">
        <v-card-title >
          <span class="pink--text font-weight-bold ">Religious Background</span>

          <v-spacer></v-spacer>
          <span v-if="clientprofile" style="cursor: pointer;text-decoration: underline;"class="primary--text caption"
            @click="$emit('ShowEdit')">
            Edit <v-icon small>mdi-chevron-right</v-icon> </span>
        </v-card-title>
        <v-divider class=""></v-divider>
        <v-row  no-gutters>
          <v-col lg="5" sm="5" md="5" cols="12">
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Religion
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    <!-- {{ $store.getters.handleEmptyString(userProfile.religion) }}    -->
{{  checkprofileCompleted(userProfile.religion,profileDetails?.religion,clientprofile) }}
                  </v-col>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Community
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    <!-- {{ $store.getters.handleEmptyString(userProfile.community) }}  -->
                    {{  checkprofileCompleted(userProfile.community,profileDetails?.community,clientprofile) }}
                  </v-col>
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Caste
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    <!-- {{ $store.getters.handleEmptyString(userProfile.caste) }} -->
                    {{  checkprofileCompleted(userProfile.caste,profileDetails?.caste,clientprofile) }}

                  </v-col>
                  <!-- <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Sub community
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    {{ $store.getters.handleEmptyString(userProfile.subcaste) }}
                  </v-col> -->
  
                </v-row>
  
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col class="d-flex align-center" lg="1" > -->
            <v-divider vertical class="highlighted-divider mr-2"></v-divider>
          <!-- </v-col> -->
          <v-col lg="5" sm="5" md="5" cols="12">
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters>
                    <!-- <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Gothra / Gothram
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    Not Specified
                  </v-col> -->
                  <v-col class="text-subtitle-2 text--disabled text-start " lg="3" sm="3" md="3" cols="4">
                    Mother Tongue
                  </v-col>
                  <v-col class="text-subtitle-2 text-start font-weight-Normal" lg="9" sm="9" md="9" cols="8">
                    <!-- {{ $store.getters.handleEmptyString(userProfile.motherTongue) }} -->
                    {{  checkprofileCompleted(userProfile.motherTongue,profileDetails?.motherTongue,clientprofile) }}

                  </v-col>
  
                </v-row>
  
              </v-col>
            </v-row>
          </v-col>
         
        </v-row>
      </v-card>
      <v-divider class="mt-1"></v-divider>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Your data here
      };
    },
    props:{
      userProfile:{},clientprofile:Boolean,profileDetails:{}
    }
  };
  </script>
  
  <style>
  /* .info-text {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  } */
  
   .highlighted-divider {
    border: 2px solid #ff4081;
    /* Highlight color */
  } 
  
  /* .v-card-title {
    background-color: #f8bbd0;
    padding: 16px;
    border-radius: 4px;
  } */
  
  /* .pa-4 {
    padding: 16px !important;
  } */
  
  /* .elevation-2 {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  } */
  </style>