<template>
    <div>
<AdminReferralDesk></AdminReferralDesk>
    </div>
</template>
<script>
import AdminReferralDesk from './AdminReferralComponents/AdminReferralDesk.vue';
export default{
    components:{
        AdminReferralDesk
    }
}
</script>