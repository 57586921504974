<template>
  <div>
    <Search @filterName="SearchName" :ClientList="ClientList" @RESET="$emit('RESET')"></Search>
    <v-card height="auto" class="elevation-3 mt-8 rounded-xxl " v-for="(client, index) in Allclients" :key="index" >
      <v-row no-gutters>
        <v-col lg="3" sm="3" md="3" cols="12">
          <!-- <div class="custom-border blurred-img">

            <v-img v-if="client.userInfo.profilepic != ''" :aspect-ratio="$vuetify.breakpoint.width > 600 ? '1': '1.3'" :src="client.userInfo.profilepic"></v-img>
            <v-img v-else :aspect-ratio="$vuetify.breakpoint.width > 600 ? '1': '1.3'"
              :src="profilePicture(client)"></v-img>
            </div> -->
            <div class="custom-border  position-relative">
  <div  :class="ClientImage  ? '': 'overlay-text'">
    <v-img contain :class="ClientImage ? '': 'blurred-img'" 
      :aspect-ratio="$vuetify.breakpoint.width > 600 ? '1' : '1.3'"
      :src="profilePicture(client)">
    </v-img>
    <div v-if="!ClientImage"  class= 'text-overlay'>
      Upload your picture to view others
    </div>
  </div>
</div>


        </v-col>
        <v-col lg="9" sm="9" md="9" cols="12" v-if="$vuetify.breakpoint.width > 600">
          <v-card-title class=" text-uppercase d-flex pa-2">
           <span class="ml-5"> {{ client.userInfo.name }}</span>
            <v-spacer></v-spacer>
            <v-btn outlined class="text-button teal--text mr-2 " small @click="$emit('showChat', client)">Chat</v-btn>
            <v-btn outlined class="text-button pink--text " small @click="$emit('clientDetials', client)">View</v-btn>
          </v-card-title>
          <v-divider class="ma-2"></v-divider>
          <v-row class="ma-2" >
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-calendar-outline</v-icon> {{
      handleEmpty(calculateAge(client.userInfo.dob)) }}, <v-icon
                  class="pink--text">mdi-human-male-height</v-icon> {{ handleEmpty(client.userInfo.height) }}</span>
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span> <v-icon class="pink--text">mdi-account-multiple</v-icon>
                {{ handleEmpty(client.userInfo.martialStatus) }}
              </span>
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-account-group</v-icon> {{
      handleEmpty(client.religiousBackGround.religion) }},{{ handleEmpty(client.religiousBackGround.caste)
                }}</span>
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span>
                <v-icon class="pink--text">mdi-home-outline</v-icon>
                {{ handleEmpty(client.locations.cityofResidence) }},{{ handleEmpty(client.locations.stateofResidence) }}
              </span>
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              {{ handleEmpty(client.religiousBackGround.motherTongue) }}
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-briefcase-outline</v-icon></span> {{
      handleEmpty(client.carrers.workingAs) }},{{ handleEmpty(client.carrers.annualIncome) }}
            </v-col>
            <v-col lg="12" sm="12" md="12" cols="12">
              <span> {{ handleEmpty(client.userInfo.about).substring(0, 15) }}</span>
              <span v-if="client.userInfo.about != ''">...</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="12">
          <div class="text-uppercase pa-3  ">
  <div class="d-flex flex-column ">
    <!-- Name and Buttons on the First Line -->
    <div class="d-flex justify-space-between align-center mb-1">
      <span class="font-weight-bold">{{ client.userInfo.name }}</span>
      <div>
        <v-btn outlined class="text-button teal--text mr-2" small @click="$emit('showChat', client)">
          Chat
        </v-btn>
       
        <!-- <v-btn outlined class="text-button pink--text" small @click="$emit('clientDetials', client)">
          View  {{ client.userInfo.clientId }}
        </v-btn> -->
        <v-btn outlined class="text-button pink--text" small @click="viewClient(client?.userInfo?.clientId )">
          View  
        </v-btn>
      </div>
    </div>
    <!-- <div class="d-flex  justify-space-between grey--text text-caption mt-3">
     <span>
      <v-icon class="pink--text">mdi-calendar-outline</v-icon>
      {{ handleEmpty(calculateAge(client.userInfo.dob)) }}
     </span> 
     <span>
      <v-icon class="pink--text">mdi-human-male-height</v-icon>
      {{ handleEmpty(client.userInfo.height) }}
     </span> 
     
    </div> -->
  </div>
</div>

</v-col>
      </v-row>
    </v-card>
  </div>
</template>


<script>
import Search from '@/components/ClientRecords/clientRecordComponents/SearchField.vue'
export default {
  components: {
    Search
  },
  data(){
    return{
ClientImage: ''
    }
  },
  methods: {
    SearchName(value) {
      // console.log("clientList");
      this.$emit('SearchName', value)
    },
    handleEmpty(value) {
      if (value == "") {
        return "Not Mentioned"
      } else {
        return value
      }
    },
    calculateAge(value) {
      if (value) {
        const today = new Date();
        const dobDate = new Date(value);
        const diff = today.getTime() - dobDate.getTime();
        const ageDate = new Date(diff);

        const years = Math.abs(ageDate.getUTCFullYear() - 1970);
        const months = ageDate.getUTCMonth();

        let ageString = '';

        if (years > 0) {
          ageString += years + (years === 1 ? ' yr ' : ' yrs ');
        }

        if (months > 0) {
          ageString += months + (months === 1 ? ' mon' : ' mons');
        }
        return ageString.trim();
      } else {
        return ""
      }
    },
    profilePicture(client) {
      if (client.userInfo.profilepic !== '') {
        return client.userInfo.profilepic;
      }
      if (client.userInfo.gender === 'M') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Male.jpg';
      }
      if (client.userInfo.gender === 'F') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Female.jpg';
      }
      return 'https://via.placeholder.com/300';
    },
    viewClient(clientId) {
      const url = `${window.location.origin}${this.$route.path}?showprofile=${encodeURIComponent(clientId)}`;
      window.location.href = url;
    },
  },
  mounted(){
this.ClientImage = localStorage.getItem("ProfilePic")
  },
  props: {
    Allclients: Array, ClientList: []
  },
  computed: {

    // filterItems(){
    //   console.log(this.posts.filter(item => item.userName = this.filterName));
    //   return this.filterName == "" ? this.posts : this.posts.filter(item => item.userName = this.filterName )

    //   // return this.filterName == "" ? Allclients : Allclients.filter(item => item.userName = this.filterName )
    // }
  },
  name: 'master',
  data() {
    return {
      clientData: {},
      filterName: "",
      posts: [
        {
          userName: 'Priya Sharma',
          about: ' "Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation.Passionate about technology and innovation."',
          clientId: 'PM001',
          pofilePic: 'https://tse2.mm.bing.net/th?id=OIP.6bAcWb70jzztpMpe2Gk4xAAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Nisha Patel',
          about: '"Lover of art, travel, and good food."',
          clientId: 'PM002',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.cQ-6ZGATxLv277VDDrIv0gHaJQ&pid=Api&P=0&h=180',
        }, {
          userName: 'Deepika Singh',
          about: 'Fitness enthusiast and outdoor adventurer.',
          clientId: 'PM003',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.DtfTFM2Na57RCG3iWt_vHgHaJQ&pid=Api&P=0&h=180',
        }, {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM004',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        }, {
          clientId: 'PM005',
          pofilePic: '',
        }, {
          clientId: 'PM006',
          pofilePic: '',
        }, {
          clientId: 'PM007',
          pofilePic: '',
        }, {
          clientId: '8',
          pofilePic: '',
        }, {
          clientId: 'PM009',
          pofilePic: '',
        }, {
          clientId: 'PM0010',
          pofilePic: '',
        }, {
          clientId: 'PM0011',
          pofilePic: '',
        }, {
          clientId: 'PM0012',
          pofilePic: '',
        },
        // Add more data objects as needed
      ]
    }
  },


}
</script>
<style scoped>
.custom-border {
  border: 8px solid palevioletred;
  border-radius: 24px;
  overflow: hidden;
}

.text-gray-600 {
  color: #757575;
}
.position-relative {
  position: relative;
}

.overlay-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
}
.blurred-img {
  filter: blur(10px);
}
</style>
