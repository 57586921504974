<template>
  <div class="App"  @contextmenu.prevent  @copy.prevent @cut.prevent>
    <v-overlay align-center v-if="$globalData.overlay" color="#FFFFFF">
      <v-flex class="d-flex justify-center">
        <Loader />
      </v-flex>
    </v-overlay>
    <CustomsnackBar></CustomsnackBar>
    <OfflineBanner></OfflineBanner>
    <NavBar v-if="$route.path != '/' && $route.path != '/auth'" ></NavBar>
        <v-main>
          <router-view />
        </v-main>
        <showprofileMain v-if="showprofile"></showprofileMain>
        <BottomNavigator  v-if="$route.path != '/' && $route.path != '/auth' && !atBottom && $vuetify.breakpoint.width < 700" class="bottom-navigator "></BottomNavigator>
  </div>
</template>
<script>
import OfflineBanner from '@/components/OfflineBanner/OfflineBanner.vue';
import Loader from '@/components/customLoader/loader.vue';
import CustomsnackBar from '@/components/customLoader/Customsnackbar.vue';
import NavBar from '@/components/Navigator/Navigator.vue';
import BottomNavigator from '@/components/Navigator/NavigationComponents/BottomNavigator.vue';
import showprofileMain from '@/components/userprofile/showprofilemain.vue';
export default {
  components: {
    OfflineBanner,
    Loader, CustomsnackBar,
    NavBar,BottomNavigator,
    showprofileMain
  },
  data(){
    return{
      atBottom: false,
      showprofile:false,
    }
  },
  watch: {
    '$route.path': function (newPath, oldPath) {
      if (newPath !== '/' && newPath !== '/auth' ) {
        // If route path is other than '/', open websocket connection
        this.openWebsocketConnection();
      } else {
        // If route path is '/', close websocket connection
        this.onWebSocketClose();

      }
    },
    '$route.query.showprofile': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          console.log("Query parameter assigned:", newValue);
          this.showprofile = true;
        } else {
          console.log("No query parameter found");
          this.showprofile = false;
        }
      },
    },
    '$store.state.ClientId': {
      handler(newClientId) {
        // Check if the newClientId is empty or not
        if (!newClientId) {
          if (this.$route.path != '/' && this.$route.path != '/auth') {
            if (localStorage.getItem("clientId") != ''){

              this.$store.commit('Setclient', localStorage.getItem("clientId"))
              this.$store.commit('setProfilePic', localStorage.getItem("ProfilePic"))
              this.storeOfflineMessage()
              
            }else{
              this.$router.push("/")
              this.onWebSocketClose()
            }
          }
          // Navigate to the logout route
        }
      },
      immediate: true // This will trigger the handler immediately when the component is created
    }
  },
  methods: {
  

    onWebSocketMessage(event) {
      // Handle incoming message
      // console.log(event, "event");
      if (event != undefined) {
        const message = JSON.parse(event.data);
        // console.log("Received message:", message,event.data);
        if (message.status == "S" && message.chatItems.length > 0){
          // console.log("this.$globalData.Messages",message.chatItems)

          this.$store.state.MessageList.push(...message.chatItems);
          localStorage.setItem("MesssageList",JSON.stringify(this.$store.state.MessageList))
            this.MessageBar('S', "New Message Recived")
          // this.$globalData.MsgCount = this.$globalData.Messages.length
        }
      }
      // Process the received message as needed
    },
  connectToWebsocket() {
    const clientId = this.$store.state.ClientId; // Replace with the actual client ID
    this.ws = new WebSocket(`wss://api.profilematcher.in/ws?ClientId=${clientId}`);
    //  this.ws = new WebSocket(`ws://192.168.45.9/handleClients?ClientId=${clientId}`);
   
    // this.ws = new WebSocket(`ws://192.168.1.35:49155/ws?ClientId=${clientId}`);
    // this.ws = new WebSocket('http://localhost:49155/ws');
    this.ws.addEventListener('open', this.onWebsocketOpen);
    this.ws.addEventListener('message', this.onWebSocketMessage);
    this.ws.addEventListener('close', this.onWebSocketClose);
    this.ws.addEventListener('error', this.onWebSocketError);
  },
  onWebsocketOpen(event) {
    const initialRequest = {
      type: "INITIAL_REQUEST",
      clientId: this.$store.state.ClientId,
      message: "Client connected"
    };

    this.ws.send(JSON.stringify(initialRequest));
    // console.log("connected to WS!", event);
    this.onWebSocketMessage()
  },
  openWebsocketConnection() {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      this.connectToWebsocket()
    }
  },
  onWebSocketClose() {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
  },
  onWebSocketError(event) {
    console.error("WebSocket error:", event);
  },
  handleScroll() {
      // Check if the user has scrolled to the bottom of the page
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.scrollHeight;

      // Set `atBottom` to true if the user is at the bottom of the page
      this.atBottom = scrollTop + windowHeight >= fullHeight - 10; // Allow for slight buffer
    },
    storeOfflineMessage(){
      const message = localStorage.getItem("MesssageList");

if (message) {
  try {
    const parsedMessages = JSON.parse(message);
    if (Array.isArray(parsedMessages) && parsedMessages.length > 0) {
      this.$store.commit('SetMessages', parsedMessages);
    }
  } catch (error) {
    console.error("Error parsing MessageList:", error);
  }
}
  
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style>
.App {
  font-family: Roboto, sans-seif;
  font-weight: 400;

}
.bottom-navigator {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10; /* Ensure it stays above other elements */
}

</style>