<template>
    <v-container>

    <v-card
      height="auto"
      class="elevation-3 mt-2 rounded-xxl"
      v-for="(client, index) in ClientList"
      :key="index"
    >
      <v-row no-gutters >
        <!-- Profile Picture -->
        <v-col lg="2" md="3" sm="4" cols="12" class="pa-2 text-center">
          <v-img
            class="custom-border "
            aspect-ratio="1"
            :src="client.profilepic"
          ></v-img>
        </v-col>
        <v-col cols="12" v-if="$vuetify.breakpoint.width < 600" >
            <div class="d-flex justify-space-around ma-3">

                <span class="ml-3 text-subtitle-1 pink--text font-weight-bold mb-2">
                    {{ client.userName }}
                </span>
                <v-spacer></v-spacer>
                <v-btn outlined class=" teal--text mr-2" small @click="$emit('showChat', client)">
                    Chat
                </v-btn>
                <v-btn outlined class=" pink--text" small @click="viewClient(client?.userInfo?.clientId )">
                    View  
                </v-btn>
            </div>
        </v-col>
        <v-col  v-else>
<div class="d-flex justify-space-around ma-3">
    <v-icon left class="mr-1">mdi-account</v-icon>{{ client.userName }}
    <v-spacer></v-spacer>
                <v-btn outlined class=" teal--text mr-2" small @click="$emit('showChat', client)">
                    Chat
                </v-btn>
                <v-btn outlined class=" pink--text" small @click="viewClient(client?.userInfo?.clientId )">
                    View  
                </v-btn>
</div>
<v-divider></v-divider>
<v-row  class="mt-1 ml-1">
    <v-col cols="12">
          <v-icon left class="mr-1">mdi-map-marker</v-icon>{{ client.city }}, {{ client.country }}
  
    </v-col>
    <v-col cols="12">
        <v-icon left class="mr-1">mdi-cake</v-icon>{{ client.age }} years old
      <v-icon left class="ml-4 mr-1">mdi-human-male-height</v-icon>{{ client.height }}
      <v-icon left class="ml-4 mr-1">mdi-heart</v-icon>{{ client.martial }}
    </v-col>
    <v-col cols="12" class="mb-2">
        <v-icon left class="mr-1">mdi-information</v-icon>{{ client.about }}
    </v-col>
</v-row>

        </v-col>

       
       
      </v-row>
    </v-card>
  
     
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {

      ClientList: [
        {
          userName: 'Priya Sharma',
          about: 'Passionate about technology and innovation.',
          clientId: 'PM001',
          profilepic: 'https://tse2.mm.bing.net/th?id=OIP.6bAcWb70jzztpMpe2Gk4xAAAAA&pid=Api&P=0&h=180',
          height: "5'4\"",
          city: 'Delhi',
          country: 'India',
          martial: 'Single',
          age: '27'
        },
        {
          userName: 'Rahul Verma',
          about: 'A fitness enthusiast and avid traveler.',
          clientId: 'PM002',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'10\"",
          city: 'Mumbai',
          country: 'India',
          martial: 'Single',
          age: '30'
        },
        {
          userName: 'Sneha Kapoor',
          about: 'Loves reading and exploring new cuisines.',
          clientId: 'PM003',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'6\"",
          city: 'Bangalore',
          country: 'India',
          martial: 'Married',
          age: '29'
        },
        {
          userName: 'Aman Gupta',
          about: 'Tech-savvy and passionate about startups.',
          clientId: 'PM004',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'9\"",
          city: 'Hyderabad',
          country: 'India',
          martial: 'Single',
          age: '28'
        },
        {
          userName: 'Neha Singh',
          about: 'Yoga practitioner and nature lover.',
          clientId: 'PM005',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'5\"",
          city: 'Chennai',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Karan Mehta',
          about: 'Music enthusiast and part-time guitarist.',
          clientId: 'PM006',
          profilepic: 'https://via.placeholder.com/150',
          height: "6'0\"",
          city: 'Pune',
          country: 'India',
          martial: 'Single',
          age: '31'
        },
        {
          userName: 'Ananya Rao',
          about: 'Entrepreneur with a passion for fashion.',
          clientId: 'PM007',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'3\"",
          city: 'Kolkata',
          country: 'India',
          martial: 'Single',
          age: '25'
        },
        {
          userName: 'Vikram Joshi',
          about: 'Adventure seeker and photographer.',
          clientId: 'PM008',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'11\"",
          city: 'Ahmedabad',
          country: 'India',
          martial: 'Married',
          age: '32'
        },
        {
          userName: 'Riya Malhotra',
          about: 'Dancer and fitness coach.',
          clientId: 'PM009',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'4\"",
          city: 'Surat',
          country: 'India',
          martial: 'Single',
          age: '27'
        },
        {
          userName: 'Arjun Kapoor',
          about: 'Sports lover and foodie.',
          clientId: 'PM010',
          profilepic: 'https://via.placeholder.com/150',
          height: "6'1\"",
          city: 'Jaipur',
          country: 'India',
          martial: 'Single',
          age: '29'
        },
        {
          userName: 'Meera Iyer',
          about: 'Writer and bookworm.',
          clientId: 'PM011',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'2\"",
          city: 'Lucknow',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Sahil Khan',
          about: 'Tech geek and traveler.',
          clientId: 'PM012',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'8\"",
          city: 'Indore',
          country: 'India',
          martial: 'Single',
          age: '28'
        },
        {
          userName: 'Pooja Reddy',
          about: 'Animal lover and NGO volunteer.',
          clientId: 'PM013',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'6\"",
          city: 'Bhopal',
          country: 'India',
          martial: 'Married',
          age: '30'
        },
        {
          userName: 'Manish Agarwal',
          about: 'Stock market analyst and investor.',
          clientId: 'PM014',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'9\"",
          city: 'Nagpur',
          country: 'India',
          martial: 'Single',
          age: '33'
        },
        {
          userName: 'Divya Menon',
          about: 'Chef and travel enthusiast.',
          clientId: 'PM015',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'5\"",
          city: 'Visakhapatnam',
          country: 'India',
          martial: 'Single',
          age: '27'
        },
        {
          userName: 'Rohit Das',
          about: 'Gamer and tech reviewer.',
          clientId: 'PM016',
          profilepic: 'https://via.placeholder.com/150',
          height: "6'0\"",
          city: 'Patna',
          country: 'India',
          martial: 'Single',
          age: '29'
        },
        {
          userName: 'Shreya Nair',
          about: 'Artist and creative designer.',
          clientId: 'PM017',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'3\"",
          city: 'Coimbatore',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Kabir Bansal',
          about: 'Entrepreneur and mentor.',
          clientId: 'PM018',
          profilepic: 'https://via.placeholder.com/150',
          height: "6'2\"",
          city: 'Ludhiana',
          country: 'India',
          martial: 'Single',
          age: '32'
        },
        {
          userName: 'Aisha Qureshi',
          about: 'Fitness freak and runner.',
          clientId: 'PM019',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'7\"",
          city: 'Kanpur',
          country: 'India',
          martial: 'Single',
          age: '28'
        },
        {
          userName: 'Vivek Sinha',
          about: 'Engineer and nature explorer.',
          clientId: 'PM020',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'10\"",
          city: 'Nashik',
          country: 'India',
          martial: 'Married',
          age: '31'
        },
        {
          userName: 'Tanya Bajaj',
          about: 'Fashion designer and blogger.',
          clientId: 'PM021',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'4\"",
          city: 'Ranchi',
          country: 'India',
          martial: 'Single',
          age: '27'
        },
        {
          userName: 'Sameer Patel',
          about: 'Marathon runner and traveler.',
          clientId: 'PM022',
          profilepic: 'https://via.placeholder.com/150',
          height: "6'1\"",
          city: 'Raipur',
          country: 'India',
          martial: 'Single',
          age: '29'
        },
        {
          userName: 'Neeraj Kaur',
          about: 'Teacher and book enthusiast.',
          clientId: 'PM023',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'5\"",
          city: 'Guwahati',
          country: 'India',
          martial: 'Married',
          age: '30'
        },
        {
          userName: 'Ishaan Roy',
          about: 'Software developer and gamer.',
          clientId: 'PM024',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'9\"",
          city: 'Amritsar',
          country: 'India',
          martial: 'Single',
          age: '28'
        },
        {
          userName: 'Kavya Desai',
          about: 'Travel vlogger and photographer.',
          clientId: 'PM025',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'6\"",
          city: 'Vadodara',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Harshita Malhotra',
          about: 'Food critic and chef.',
          clientId: 'PM026',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'3\"",
          city: 'Thane',
          country: 'India',
          martial: 'Single',
          age: '27'
        },
        {
          userName: 'Aditya Reddy',
          about: 'Finance professional and runner.',
          clientId: 'PM027',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'11\"",
          city: 'Faridabad',
          country: 'India',
          martial: 'Single',
          age: '31'
        },
        {
          userName: 'Bhavna Kapoor',
          about: 'Interior designer and artist.',
          clientId: 'PM028',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'4\"",
          city: 'Meerut',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Yash Chauhan',
          about: 'Engineer and biker.',
          clientId: 'PM029',
          profilepic: 'https://via.placeholder.com/150',
          height: "6'0\"",
          city: 'Jabalpur',
          country: 'India',
          martial: 'Single',
          age: '30'
        },
        {
          userName: 'Sanya Batra',
          about: 'Marketing professional and dancer.',
          clientId: 'PM030',
          profilepic: 'https://via.placeholder.com/150',
          height: "5'6\"",
          city: 'Dehradun',
          country: 'India',
          martial: 'Single',
          age: '27'
        }
      ]


      };
    },
  
  };
  </script>
  
  <style scoped>
  .custom-border {
  border: 8px solid palevioletred;
  border-radius: 24px;
  overflow: hidden;
}
  /* .v-card {
    transition: transform 0.2s ease;
  }
  .v-card:hover {
    transform: scale(1.03);
  }
  .custom-border {
    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
  }
  .blurred-img {
    filter: blur(4px);
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 0.9rem;
    text-align: center;
  } */
  </style>
  