<template>
    <div>
        <v-bottom-sheet v-model="bottomDialog">
      <v-sheet class="pa-4 text-center">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-icon color="pink" @click="$emit('closeBottomSheet')">mdi-close</v-icon>
        </div>
        <h3><v-icon color="pink ">mdi-lock</v-icon> Access Restricted</h3>
        <p>Please sign up or log in to continue your process.</p>
        <div class="button-group mt-3">
          <v-btn color="pink " class="action-button" @click="onSignUp">Sign Up</v-btn>
          <v-btn outlined color="pink " class="action-button" @click="onLogin">Login</v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    </div>
</template>
<script>
export default{
 props:{
  bottomDialog:Boolean
 },
  methods:{
    onSignUp() {
        this.$router.push('/auth');
      },
      onLogin() {
        this.$router.push('/auth');
      },

  }
}
</script>
<style scoped>
.auth-card {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
}

.brand-name {
  color: #e91e63;
  font-weight: bold;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.action-button {
  flex: 1;
  border-radius: 30px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.action-button:hover {
  transform: scale(1.07);
}
</style>