<template>
    <div>
<prelogin></prelogin>
    </div>
</template>
<script>
import prelogin from '@/components/prelogin/preloginmain.vue';
export default{
    components:{
        prelogin
    },
    data(){
        return{

        }
    }
}
</script>