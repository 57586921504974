<template>
    <div>
        <v-card elevation="0">
            <v-tabs v-model="activeTab" left>
                <v-tab>My Referrals</v-tab>
                <v-tab>Completed Payments</v-tab>
            </v-tabs>
                <div class="mt-4">
                    <v-alert
                     icon="mdi-currency-inr"
      dense
      text
      type="success"
    >
    <span class="font-weight-medium">
            Referral Program Details:
        </span>
        <ul class="mt-2 mb-0 pl-6">
            <li class="text-caption">
                After reaching <strong>10 clients</strong>, you will be eligible to claim your referral rewards.
            </li>
            <li class="text-caption">
                For each successful referral, <strong>₹10</strong> will be added to your account.
            </li>
            <li class="text-caption">
                Upon claiming, the total referral amount will be credited to your account within the next 
                <strong>10 working days</strong>.
            </li>
            <li class="text-caption">
    Any <strong>fake or fraudulent referrals</strong> will be detected and deducted from your referral balance.
  </li>
        </ul>
    </v-alert>
</div>
            <v-window v-model="activeTab" class="elevation-0 ">
                <v-window-item v-for="(tab, index) in tabs" :key="index">
                    <v-card v-if="activeTab === index" >
                        <div class="d-flex justify-space-between">
                            <div>

                                
                                <span class="text-subtitle-1 text-capitalize">
                                <v-icon left color="blue darken-4" medium>
                                    {{ currentIcon }} 
                                </v-icon>
                                <span >
                                    {{ currentTitle }} 
                                </span>
                            </span>
                        </div>
                            <div class="d-flex">
                                
                            <span  v-if="activeTab == 0 && Referral?.referalCode == ''" class="mr-3">
                                Here You can Generate a Referal code   <a @click="$emit('gencode')" href="javascript:void(0)" role="button" class="text-link"> Generate Code</a>
                            </span>
                            <span  v-if="activeTab == 0 && Referral?.referalCode != ''" class="mr-3" >
                                 Referal code :  {{ Referral?.referalCode }}
                            </span>
                            <v-divider vertical></v-divider>
                            <span v-if="Referral?.totalAmount  && activeTab == 0"  class="mr-5"> 
Total Amount: <v-icon small class="primary--text">mdi-currency-inr</v-icon> {{ Referral?.totalAmount }}  
                            </span>
                            <span v-if="Referral?.receivedAmount  && activeTab == 1">
                                Received Amount: <p class="primary--text"><v-icon small class="teal--text">mdi-currency-inr</v-icon> {{ Referral?.receivedAmount }} </p>
                            </span>
                            <v-btn class="mb-2"  x-small outlined  color="teal" v-if="activeTab == 0"
                            :disabled="Referral?.totalAmount < 100" @click="$emit('')">
                                Claim 
                            </v-btn>
                        </div>


                        </div>
                        <v-card class="elevation-5">
                            <v-data-table :headers="Headers" :items="currentItems" :items-per-page="10"
                                fixed-header   :search="search">
                            </v-data-table>
                        </v-card>
                    </v-card>
                </v-window-item>
            </v-window>
        </v-card>

    </div>
</template>
<script>
export default {
    data(){
        return{
            activeTab:0,
            loading:false,
            tabs: ['My Referrals', 'Completed Payments'],
            titleIcon: ['mdi-account-multiple', 'mdi-cash-multiple'],
            Headers: [
                { text: 'S.No', align: 'start', sortable: false, value: 'id' },
                { text: "ClientName", value: "clientName" },
                { text: "ClientId", value: "clientId" },
                { text: "Created Date", value: "createdDate" },
            ],
            details: [],
            search:""
        }
    },
    props:{
Referral:{},
RefferalCode:String
    },
    computed: {
      
        currentItems() {
            return this.activeTab === 0 ? this.Referral?.currentRefferals : this.Referral?.completedRefferals;
        },
        currentTitle() {
            return this.tabs[this.activeTab];
        },
        currentIcon() {
            return this.titleIcon[this.activeTab];
        }
    },

}
</script>