<template>
    <div>
        <preloginheader></preloginheader>
<preloginMain @openbottomsheet="bottomDialog = true"></preloginMain>
<preloginbottomsheet :bottomDialog="bottomDialog" @closeBottomSheet="bottomDialog = false"></preloginbottomsheet>
    </div>
</template>
<script>
import preloginMain from './prelogincomponents/prelogin.vue';
import preloginheader from './prelogincomponents/preloginheader.vue';
import preloginbottomsheet  from './prelogincomponents/prologinbottomsheet.vue'
export default{
    data(){
        return{
bottomDialog:false
        }
    },
    components:{
        preloginMain,preloginheader,preloginbottomsheet
    }
}
</script>