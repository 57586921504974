<template>
    <v-container>

    <v-card
      height="auto"
      class="elevation-3 mt-2 rounded-xxl"
      v-for="(client, index) in ClientList"
      :key="index"
    >
      <v-row no-gutters >
        <!-- Profile Picture -->
        <v-col lg="2" md="3" sm="4" cols="12" class="pa-2 text-center">
          <v-img
            class="custom-border blurred-img"
            aspect-ratio="1"
            :src="client.profilepic"
            contain
          ></v-img>
        </v-col>
        <v-col cols="12" v-if="$vuetify.breakpoint.width < 600" >
            <div class="d-flex justify-space-around ma-3">

                <span class="ml-3 text-subtitle-1 pink--text font-weight-bold mb-2">
                    {{ client.userName }}
                </span>
                <v-spacer></v-spacer>
                <v-btn outlined class=" teal--text mr-2" small @click="$emit('openbottomsheet')">
                    Chat
                </v-btn>
                <v-btn outlined class=" pink--text" small @click="$emit('openbottomsheet')">
                    View  
                </v-btn>
            </div>
        </v-col>
        <v-col  v-else>
<div class="d-flex justify-space-around ma-3">
    <v-icon left class="mr-1 pink--text">mdi-account</v-icon>{{ client.userName }}
    <v-spacer></v-spacer>
                <v-btn outlined class=" teal--text mr-2" small @click="$emit('openbottomsheet')">
                    Chat
                </v-btn>
                <v-btn outlined class=" pink--text" small @click="$emit('openbottomsheet')">
                    View  
                </v-btn>
</div>
<v-divider></v-divider>
<v-row  class="mt-1 ml-1">
    <v-col cols="12">
          <v-icon left class="mr-1 pink--text">mdi-map-marker</v-icon>{{ client.city }}, {{ client.country }}
  
    </v-col>
    <v-col cols="12">
        <v-icon left class="mr-1 pink--text">mdi-cake</v-icon>{{ client.age }} years old
      <v-icon left class="ml-4 mr-1 pink--text">mdi-human-male-height</v-icon>{{ client.height }}
      <v-icon left class="ml-4 mr-1 pink--text">mdi-heart</v-icon>{{ client.martial }}
    </v-col>
    <v-col cols="12" class="mb-2">
        <v-icon left class="mr-1 pink--text">mdi-information</v-icon>{{ client.about }}
    </v-col>
</v-row>

        </v-col>

       
       
      </v-row>
    </v-card>
  
     
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {

      ClientList: [
        {
          userName: 'Priya Sharma',
          about: 'Passionate about technology and innovation.',
          clientId: 'PM001',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9QA9nMEY2QQ9cTi8kmQbDj70mhh3Z6e0ZJg&s',
          height: "5'4\"",
          city: 'Delhi',
          country: 'India',
          martial: 'Single',
          age: '27'
        },
        {
          userName: 'Rahul Verma',
          about: 'A fitness enthusiast and avid traveler.',
          clientId: 'PM002',
          profilepic: 'https://i.pinimg.com/736x/09/f8/07/09f80744c589a5017b11b8a494be89c4.jpg',
          height: "5'10\"",
          city: 'Mumbai',
          country: 'India',
          martial: 'Single',
          age: '30'
        },
        {
          userName: 'Sneha Kapoor',
          about: 'Loves reading and exploring new cuisines.',
          clientId: 'PM003',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1kRMNN4aC5ichulaUkNaj7Rt2smiUlO_XSw&s',
          height: "5'6\"",
          city: 'Bangalore',
          country: 'India',
          martial: 'Married',
          age: '29'
        },
        {
          userName: 'Aman Gupta',
          about: 'Tech-savvy and passionate about startups.',
          clientId: 'PM004',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQKcC8foiQT9CLPaxHpK_Au4cynIbivATKfw&s',
          height: "5'9\"",
          city: 'Hyderabad',
          country: 'India',
          martial: 'Single',
          age: '28'
        },
        {
          userName: 'Neha Singh',
          about: 'Yoga practitioner and nature lover.',
          clientId: 'PM005',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcYl1JXcpPSyJKYHsc19eiraXATR-Whc_qGA&s',
          height: "5'5\"",
          city: 'Chennai',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Karan Mehta',
          about: 'Music enthusiast and part-time guitarist.',
          clientId: 'PM006',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxoMuAc9UAAarbfeIlJV0ixuv9Jy0CJfA1dA&s',
          height: "6'0\"",
          city: 'Pune',
          country: 'India',
          martial: 'Single',
          age: '31'
        },
        {
          userName: 'Ananya Rao',
          about: 'Entrepreneur with a passion for fashion.',
          clientId: 'PM007',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR17N7hukeQYLxIB1azVpp2tjQrmTcfqcpoCNNUpTdgMYUYKMyhlHPOoR0FKtRBOIM6p08&usqp=CAU',
          height: "5'3\"",
          city: 'Kolkata',
          country: 'India',
          martial: 'Single',
          age: '25'
        },
        {
          userName: 'Vikram Joshi',
          about: 'Adventure seeker and photographer.',
          clientId: 'PM008',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyOHPdG0NTX82Z5y-szuMXyWPxW5BzPhNWa3qBc4InnpDelDy2C3aGpHEyPrHX7TCgKnk&usqp=CAU',
          height: "5'11\"",
          city: 'Ahmedabad',
          country: 'India',
          martial: 'Married',
          age: '32'
        },
        {
          userName: 'Riya Malhotra',
          about: 'Dancer and fitness coach.',
          clientId: 'PM009',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREwwKVXTLeKnnOnuesu8eVyHSOhZk_H_nT8auqEUNCUvaTomhLmjxNGQrW3KJZewEJ6tI&usqp=CAU',
          height: "5'4\"",
          city: 'Surat',
          country: 'India',
          martial: 'Single',
          age: '27'
        },
        {
          userName: 'Arjun Kapoor',
          about: 'Sports lover and foodie.',
          clientId: 'PM010',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyamtQJqgTm1L53e9CPqto6Y7w_YVVI9axFw&s',
          height: "6'1\"",
          city: 'Jaipur',
          country: 'India',
          martial: 'Single',
          age: '29'
        },
        {
          userName: 'Meera Iyer',
          about: 'Writer and bookworm.',
          clientId: 'PM011',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF4hwo32A1vwktU9_tDMOvV7NDT23_2wCwUQ&s',
          height: "5'2\"",
          city: 'Lucknow',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Sahil Khan',
          about: 'Tech geek and traveler.',
          clientId: 'PM012',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgoaxaMdp2H3FIge8fgE8KWGWoq6NWQKTWXg&s',
          height: "5'8\"",
          city: 'Indore',
          country: 'India',
          martial: 'Single',
          age: '28'
        },
        {
          userName: 'Pooja Reddy',
          about: 'Animal lover and NGO volunteer.',
          clientId: 'PM013',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXQy3DuzBkEe_A5hDh8OZKsgTc2929QR1zog&s',
          height: "5'6\"",
          city: 'Bhopal',
          country: 'India',
          martial: 'Married',
          age: '30'
        },
        {
          userName: 'Manish Agarwal',
          about: 'Stock market analyst and investor.',
          clientId: 'PM014',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-KuoDnAtZyXNF3KEJA8t2gbuK7QgJsW-jzg&s',
          height: "5'9\"",
          city: 'Nagpur',
          country: 'India',
          martial: 'Single',
          age: '33'
        },
        {
          userName: 'Shreya Nair',
          about: 'Artist and creative designer.',
          clientId: 'PM017',
          profilepic: 'https://i.pinimg.com/736x/53/bc/83/53bc83e0c9f678919cbe8d7abb425c50.jpg',
          height: "5'3\"",
          city: 'Coimbatore',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Kabir Bansal',
          about: 'Entrepreneur and mentor.',
          clientId: 'PM018',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSN0Mox4rxGijzvNABVjEPWs_UOoVRTLq-rjHjdxzcobn1ayObUvU8pGpcsBuGOH_DV98A&usqp=CAU',
          height: "6'2\"",
          city: 'Ludhiana',
          country: 'India',
          martial: 'Single',
          age: '32'
        },
        {
          userName: 'Aisha Qureshi',
          about: 'Fitness freak and runner.',
          clientId: 'PM019',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQetJ8g6Q5eOWEOuUa-7uI4iWE5YqIWO9PvkdyNpoQ6J_ildA1zSLKqi_YlzcDOWVyIDRU&usqp=CAU',
          height: "5'7\"",
          city: 'Kanpur',
          country: 'India',
          martial: 'Single',
          age: '28'
        },
        {
          userName: 'Kavya Desai',
          about: 'Travel vlogger and photographer.',
          clientId: 'PM025',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv3sU625mlz02oqftRjb2xtxnJPiBJsiE-9XTz1kohrHTdcgW5QgbERvH5IeK13v1EgDU&usqp=CAU',
          height: "5'6\"",
          city: 'Vadodara',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
        {
          userName: 'Bhavna Kapoor',
          about: 'Interior designer and artist.',
          clientId: 'PM028',
          profilepic: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpsvz5xF4mmj3VzgvKKz9jZgJD1bkcWDM_mSIDB6oNprGsf68wW3-C-qcZtZgh1cnV0oA&usqp=CAU',
          height: "5'4\"",
          city: 'Meerut',
          country: 'India',
          martial: 'Single',
          age: '26'
        },
      ]


      };
    },
  
  };
  </script>
  
  <style scoped>
  .custom-border {
  border: 8px solid palevioletred;
  border-radius: 24px;
  overflow: hidden;
}
.blurred-img {
    filter: blur(4px);
  }

  /* .v-card {
    transition: transform 0.2s ease;
  }
  .v-card:hover {
    transform: scale(1.03);
  }
  .custom-border {
    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
  }
  .blurred-img {
    filter: blur(4px);
  }
  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 0.9rem;
    text-align: center;
  } */
  </style>
  