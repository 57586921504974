<template>
  <div>
    <v-card class="elevation-0" v-if="userProfile.userInfo != undefined">
      <v-row>
        <v-col lg="3" sm="3" md="3" cols="12">
          
          <div v-if="!clientprofile && $store.state.ProfilePic == ''">
            <v-card
    height="300"
  >
            <v-overlay absolute :value="true"  :opacity="1">
              <span>
                upload Your photo to view others
              </span>
            </v-overlay>
            </v-card>
          </div>
          <div v-else>
          <v-img  v-if="getprofile.userInfo.profilepic != ''" :src="getprofile.userInfo.profilepic" contain
            aspect-ratio="1" @mouseover="showoverlay = true" @mouseleave="showoverlay = false">
            <v-overlay absolute top right :value="showoverlay">
              <v-file-input accept=".jpg, .jpeg, .png" v-model="pic" hide-input prepend-icon="mdi-upload"
                @change="$emit('profilepic', pic)"></v-file-input>
            </v-overlay>
          </v-img>
          <v-img v-else aspect-ratio="1" :src="profilePicture(getprofile.userInfo)" @mouseover="showoverlay = true"
            @mouseleave="showoverlay = false">
            <v-overlay absolute top right :value="showoverlay">
              <v-file-input accept=".jpg, .jpeg, .png" v-model="pic" hide-input prepend-icon="mdi-upload"
                @change="$emit('profilepic', pic)"></v-file-input>
            </v-overlay>
          </v-img>
        </div>

        </v-col>
        <v-col cols="12" class="d-flex justify-center" v-if="$vuetify.breakpoint.width < 600 && clientprofile">
    <v-btn class="white--text" color="pink" outlined @click="triggerFileUpload">
      <v-icon>mdi-upload</v-icon> Upload Profile
    </v-btn>
    
    <!-- Hidden file input -->
    <input
      ref="fileInput"
      type="file"
      accept="image/jpeg, image/png"
      style="display: none"
      @change="handleFileUpload"
    />
  </v-col>
        <v-col lg="9" sm="9" md="9" cols="12">
          <v-card-title class="text-uppercase d-flex ma-2">
            <!-- {{ userProfile.userInfo.name }} -->
            {{checkprofileCompleted(userProfile.userInfo.name,profileDetails?.userInfo.name,clientprofile)}}
            <!-- Prashanth -->
            <v-spacer></v-spacer>
            <v-icon class="mr-3" color="primary" @click="copyLink($store.state.ClientId)">
              mdi-content-copy
            </v-icon>
            <v-icon color="red"  @click="shareLink($store.state.ClientId)">
              mdi-share-variant-outline
            </v-icon>
          </v-card-title>
          <v-divider class="ma-2"></v-divider>
          <v-row class="ma-2">
            <v-col lg="6" sm="6" md="6" cols="12" v-if="$vuetify.breakpoint.width > 600">
              <!-- <span><v-icon  class="pink--text">mdi-calendar-outline</v-icon> {{  userProfile.dob }},   <v-icon class="pink--text">mdi-human-male-height</v-icon> {{ handleEmpty(userProfile.user_height) }}</span> -->
              <span><v-icon class="pink--text">mdi-calendar-outline</v-icon> 
              <!-- {{ handleEmpty(calculateAge(userProfile.userInfo.dob)) }}, -->
              {{checkprofileCompleted(calculateAge(userProfile.userInfo.dob),profileDetails?.userInfo.dob,clientprofile)}},
               <v-icon class="pink--text">mdi-human-male-height</v-icon>
               <!-- {{handleEmpty(userProfile.userInfo.height) }} -->
               {{checkprofileCompleted(userProfile.userInfo.height,profileDetails?.userInfo.height,clientprofile)}}
                  </span>

            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span> <v-icon class="pink--text">mdi-account-multiple</v-icon>
                <!-- Never marrried -->
                <!-- {{ handleEmpty(userProfile.userInfo.martialStatus) }} -->
                {{checkprofileCompleted(userProfile.userInfo.martialStatus,profileDetails?.userInfo.martialStatus,clientprofile)}}
              </span>
              <!-- Never Married -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-account-group</v-icon> 
              <!-- {{  handleEmpty(userProfile.religiousBackGround.religion) }},{{
                  handleEmpty(userProfile.religiousBackGround.caste) }} -->
                  {{checkprofileCompleted(userProfile.locations.cityofResidence,profileDetails?.locations.cityofResidence,clientprofile)}},{{checkprofileCompleted(userProfile.locations.stateofResidence,profileDetails?.locations.stateofResidence,clientprofile)}}
                </span>
              <!-- Hindu,Naidu -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span>
                <v-icon class="pink--text">mdi-home-outline</v-icon>
                <!-- {{ handleEmpty(userProfile.locations.cityofResidence) }},{{
                  handleEmpty(userProfile.locations.stateofResidence) }} -->
                  {{checkprofileCompleted(userProfile.locations.cityofResidence,profileDetails?.locations.cityofResidence,clientprofile)}},{{checkprofileCompleted(userProfile.locations.stateofResidence,profileDetails?.locations.stateofResidence,clientprofile)}}
              </span>
              <!-- chennai,TamilNadu -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <!-- {{ handleEmpty(userProfile.religiousBackGround.motherTongue) }} -->
              {{checkprofileCompleted(userProfile.religiousBackGround.motherTongue,profileDetails?.religiousBackGround.motherTongue,clientprofile)}}
              <!-- Telegu,Not Working -->
            </v-col>
            <v-col lg="6" sm="6" md="6" cols="12">
              <span><v-icon class="pink--text">mdi-briefcase-outline</v-icon></span> 
              <!-- {{
                handleEmpty(userProfile.carrers.workingAs) }},{{ handleEmpty(userProfile.carrers.annualIncome) }} -->
                {{checkprofileCompleted(userProfile.carrers.workingAs,profileDetails?.carrers.workingAs,clientprofile)}},{{checkprofileCompleted(userProfile.carrers.annualIncome,profileDetails?.carrers.annualIncome,clientprofile)}}
              <!-- Telegu,Not Working -->
            </v-col>
            <v-col lg="12" sm="12" md="12" cols="12">
              <span> 
                <!-- {{ handleEmpty(userProfile.userInfo.about) }} -->
{{checkprofileCompleted(userProfile.userInfo.about,profileDetails?.userInfo.about,clientprofile)}}

              </span>
              
              <!-- Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, eius enim sapiente assumenda facere esse eligendi ab, impedit qui, dolore provident totam quis molestias officia et sit harum voluptates veniam. -->
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" md="4" v-if="$vuetify.breakpoint.width > 600 && clientprofile">
          <v-btn class="white--text " small color="pink" outlined @click="triggerFileUpload">
      <v-icon>mdi-upload</v-icon> Upload Profile
    </v-btn>
    
    <input
      ref="fileInput"
      type="file"
      accept="image/jpeg, image/png"
      style="display: none"
      @change="handleFileUpload"
    />
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>
<script>
export default {
  data() {
    return {
      showoverlay: false,
      // profile:"https://tse2.mm.bing.net/th?id=OIP.6bAcWb70jzztpMpe2Gk4xAAAAA&pid=Api&P=0&h=180",
      profile: "",

      pic: null
    }
  },
  props: {
    userProfile: {},clientprofile:Boolean,profileDetails:{}
  },
  computed : {
    getprofile(){
      return this.clientprofile ? this.userProfile : this.profileDetails
    }
  },
  methods: {
    handleEmpty(value) {
      if (value == "") {
        return "Not Mentioned"
      } else {
        return value
      }


    },
  copyLink(clientId) {
    const url = `${window.location.origin}${this.$route.path}?showprofile=${encodeURIComponent(clientId)}`;

    // Copy link to clipboard
    navigator.clipboard.writeText(url)
      .then(() => {
        this.MessageBar("S", "Link copied")
      })
      .catch(err => {
        console.error("Failed to copy: ", err);
      });
  },
  shareLink(clientId) {
    const shareUrl = `${window.location.origin}${this.$route.path}?showprofile=${encodeURIComponent(clientId)}`;

    if (navigator.share) {
      navigator.share({
        title: "Check this out!",
        text: "Here is the link:",
        url: shareUrl,
      }).catch(error => console.error("Error sharing:", error));
    } else {
      alert("Sharing not supported on this browser");
    }
  },

    triggerFileUpload() {
      // Programmatically trigger the file input click
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      
      // Check if the file is valid (jpg, jpeg, or png)
      if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
        // Save the valid file in the v-model data (profileImage)
        this.$emit('profilepic', file)  ;
        console.log("Valid file selected:");
      } else {
        // Alert if the file is not of the correct type
        alert("Please upload a valid image file (jpg, jpeg, png).");
        // Reset the file input if needed
        this.$refs.fileInput.value = null;
      }
    },
    profilePicture(client) {
      if (client.profilepic !== '') {
        return client.profilepic;
      }
      if (client.gender === 'M') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Male.jpg';
      }
      if (client.gender === 'F') {
        return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Female.jpg';
      }
    },
    calculateAge(value) {
      if (value) {
        const today = new Date();
        const dobDate = new Date(value);
        const diff = today.getTime() - dobDate.getTime();
        const ageDate = new Date(diff);

        const years = Math.abs(ageDate.getUTCFullYear() - 1970);
        const months = ageDate.getUTCMonth();

        let ageString = '';

        if (years > 0) {
          ageString += years + (years === 1 ? ' yr ' : ' yrs ');
        }

        if (months > 0) {
          ageString += months + (months === 1 ? ' mon' : ' mons');
        }
        return ageString.trim();
      } else {
        return "Not Mentioned"
      }
    },
  }
}
</script>