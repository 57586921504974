<template>
    <div>
        <v-window v-if="userProfile.userInfo!=undefined" v-model="ShowInfo" :touchless="true">
            <v-window-item :value="true" >
                <BasicDetails :userProfile="userProfile.userInfo" @ShowEdit="ShowInfo = false" 
                :clientprofile="clientprofile" :profileDetails="profileDetails?.userInfo" ></BasicDetails>
            </v-window-item>
            <v-window-item :value="false">
                <BasicDetailsEdit @userinfo="submitForm" :minDate="minDate" :maxDate="maxDate" :dropDownList="dropDownList" :userInfo="userProfile.userInfo"  @BasicEdit="ShowInfo = true"       @closeInfo="ShowInfo = true"></BasicDetailsEdit>
            </v-window-item>
        </v-window>
        <v-window  v-if="userProfile.religiousBackGround!=undefined" v-model="ShowReligious" :touchless="true">
            <v-window-item :value="true">
                <religiousBackground :userProfile="userProfile.religiousBackGround" @ShowEdit="ShowReligious = false"   :clientprofile="clientprofile" :profileDetails="profileDetails?.religiousBackGround"
           ></religiousBackground>
            </v-window-item>
            <v-window-item :value="false">
                <religiousBackgroundEdit @religious="submitForm" @closeInfo="ShowReligious = true" :religiousBackGround="userProfile.religiousBackGround" :dropDownList="dropDownList"  ></religiousBackgroundEdit>
            </v-window-item>
        </v-window>
        <!-- <v-window v-if="userProfile.astro!=undefined"  v-model="ShowAstro" :touchless="true">
            <v-window-item :value="true">
                <AstroDetails :userProfile="userProfile.astro" @ShowEdit="ShowAstro = false" 
                 :clientprofile="clientprofile" :profileDetails="profileDetails"></AstroDetails>
            </v-window-item>
            <v-window-item  :value="false">
                <AstroDetailsEdit @astro="submitForm" @closeInfo="ShowAstro = true" :dropDownList="dropDownList"  :astro="userProfile.astro"></AstroDetailsEdit>
            </v-window-item>
        </v-window>
        <v-window v-if="userProfile.familyDetails!=undefined" v-model="ShowFamily" :touchless="true">
            <v-window-item :value="true">
                <FamilyDetails :userProfile="userProfile.familyDetails" @ShowEdit="ShowFamily = false"  :clientprofile="clientprofile" :profileDetails="profileDetails">
                </FamilyDetails>
            </v-window-item>
            <v-window-item :value="false">
                <FamilyEdit @family="submitForm" @closeInfo="ShowFamily = true" :dropDownList="dropDownList"  :familyDetails="userProfile.familyDetails" ></FamilyEdit>
            </v-window-item>
        </v-window> -->
        <v-window  v-if="userProfile.carrers!=undefined" v-model="ShowCarres" :touchless="true">
            <v-window-item :value="true">
                <carrers  :clientprofile="clientprofile" :profileDetails="profileDetails?.carrers" :userProfile="userProfile.carrers" @ShowEdit="ShowCarres = false" ></carrers>
            </v-window-item>
            <v-window-item :value="false">
                <CarrerEdit @carrer="submitForm" @closeInfo="ShowCarres = true" :dropDownList="dropDownList"  :career="userProfile.carrers"></CarrerEdit>
            </v-window-item>
        </v-window>
        <v-window v-if="userProfile.locations!=undefined" v-model="showLocation" :touchless="true">
            <v-window-item :value="true">
                <Location  :clientprofile="clientprofile" :profileDetails="profileDetails?.locations" :userProfile="userProfile.locations" @ShowEdit="showLocation = false" ></Location>
            </v-window-item>
            <v-window-item :value="false">
                <LocationEdit @location="submitForm" @closeInfo="showLocation = true" :dropDownList="dropDownList"  :locations="userProfile.locations" ></LocationEdit>
            </v-window-item>
        </v-window>   
    </div>
</template>
<script>
import BasicDetailsEdit from '../profileDetailsForm/BasicDetailsEdit.vue'
// import AstroDetailsEdit from '../profileDetailsForm/AstroDetailsEdit.vue'
import CarrerEdit from '../profileDetailsForm/CarrerEdit.vue'
// import FamilyEdit from '../profileDetailsForm/FamilyEdit.vue'
import LocationEdit from '../profileDetailsForm/LocationEdit.vue'
import religiousBackgroundEdit from '../profileDetailsForm/religiousBackgroundEdit.vue'
import Location from './locationDetails.vue'
import BasicDetails from './BasicDetails.vue'
import religiousBackground from './religiousBackground.vue'
// import AstroDetails from './AstroDetails.vue'
import carrers from './EducationCarrers.vue'
// import FamilyDetails from './FamilyDetails.vue'
import EventServices from '@/services/EventServices.js'
export default {
    components: {
        Location, BasicDetails, religiousBackground, carrers, BasicDetailsEdit, religiousBackgroundEdit, LocationEdit, CarrerEdit ,
        // AstroDetailsEdit,AstroDetails,FamilyEdit,FamilyDetails
    },
    data() {
        return {
            ShowInfo: true,
            ShowReligious: true,
            ShowFamily: true,
            ShowAstro: true,
            ShowCarres: true,
            showLocation: true,


        }

    },
    props: {
        minDate: String, maxDate: String,
        userProfile: {}, dropDownList: {},
        profileDetails:{},clientprofile:Boolean
    },
    methods:{
        submitForm(Indicator) {
      console.log('submitForm', this.userProfile, Indicator);
      this.$globalData.overlay = true
      EventServices.SetUserProfile(this.userProfile, Indicator)
        .then((response) => {
          if (response.data.status == "S") {
            this.MessageBar("S", response.data.errMsg)
            this.ShowInfo = true
            this.ShowReligious = true
            this.ShowFamily = true
            this.ShowAstro = true
            this.ShowCarres = true
            this.showLocation = true
            this.$emit('getUserProfile')
            this.$globalData.overlay = false

            this.loading = false
          } else {
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })
        .catch((error) => {
          this.$globalData.overlay = false
          this.MessageBar("E", error);
        });
      // console.log(UserDetails,"submitForm");
    }
    }
}

</script>