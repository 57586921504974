<template>
    <div>
        <HomeMain></HomeMain>
    </div>
</template>
<script>
import HomeMain from '@/components/Home/HomeMain.vue';
export default {
    components:{
        HomeMain
    }
}
</script>