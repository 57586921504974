<template>
    <div>
        <profileHeader ></profileHeader>
        <!-- All Matches -->
<profileMatches v-if="Dashboardprofiles.allCount > 0" :ClientList="Dashboardprofiles.alllist" :Tittle="AllMatches"></profileMatches>
<v-divider></v-divider>
        <!-- New Matches -->
<profileMatches v-if="Dashboardprofiles.newCount > 0" :ClientList="Dashboardprofiles.newlist" :Tittle="New"></profileMatches>
<v-divider></v-divider>
         <!-- Nearby Matches -->
<profileMatches v-if="Dashboardprofiles.nearbyCount > 0" :ClientList="Dashboardprofiles.nearbylist" :Tittle="Nearby"></profileMatches>
<v-divider></v-divider>
          <!-- shortlisted Mathes-->
<profileMatches v-if="Dashboardprofiles.shortlistedCount > 0" :ClientList="Dashboardprofiles.shortlistedlist" :Tittle="shortlisted"></profileMatches>
<v-divider></v-divider>

          <!-- Intrested Matches on You -->
<profileMatches v-if="Dashboardprofiles.intrestedCount > 0" :ClientList="Dashboardprofiles.intrestedlist" :Tittle="Intrested"></profileMatches>
<v-divider></v-divider>
           <!-- view by You -->
<profileMatches v-if="Dashboardprofiles.viewbyCount > 0" :ClientList="Dashboardprofiles.viewbylist" :Tittle="viewby"></profileMatches>
<v-divider></v-divider>
            <!-- viewed By You -->
<profileMatches v-if="Dashboardprofiles.viewedByCount > 0" :ClientList="Dashboardprofiles.viewedyoulist" :Tittle="viewedyou"></profileMatches>
<v-divider></v-divider>
             <!-- similar hobbie -->
<profileMatches v-if="Dashboardprofiles.hobbieCount > 0" :ClientList="Dashboardprofiles.hobbielist" :Tittle="hobbie"></profileMatches>
<v-divider></v-divider>
              <!-- mutual matches -->
<profileMatches v-if="Dashboardprofiles.mutualCount > 0" :ClientList="Dashboardprofiles.mutuallist" :Tittle="mutual"></profileMatches>
<v-divider></v-divider>
    </div>
</template>
<script>
import profileHeader from './profileHeader.vue';
import profileMatches from './profileMatches.vue';
export default{
    components:{
        profileMatches,profileHeader
    },
    props:{
      Dashboardprofiles:{}
    },

    data(){
        return{
            AllMatches:'all',
            New:'new',
            Nearby:'nearby',
            shortlisted:'shortlisted',
            Intrested:'intrested',
            viewby:'viewby',
            viewedyou:'viewedyou',
            hobbie:'hobbie',
            mutual:'mutual',

            ClientList: [
        {
          userName: 'Priya Sharma',
          about: 'Passionate about technology and innovation.',
          clientId: 'PM001',
          pofilePic: 'https://tse2.mm.bing.net/th?id=OIP.6bAcWb70jzztpMpe2Gk4xAAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Nisha Patel',
          about: 'Lover of art, travel, and good food.',
          clientId: 'PM002',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.cQ-6ZGATxLv277VDDrIv0gHaJQ&pid=Api&P=0&h=180',
        },
        {
          userName: 'Deepika Singh',
          about: 'Fitness enthusiast and outdoor adventurer.',
          clientId: 'PM003',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.DtfTFM2Na57RCG3iWt_vHgHaJQ&pid=Api&P=0&h=180',
        },
        {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM004',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM005',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM010',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM006',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM009',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        },
        {
          userName: 'Neha Gupta',
          about: 'Bookworm, coffee addict, and aspiring writer.',
          clientId: 'PM007',
          pofilePic: 'https://tse4.mm.bing.net/th?id=OIP.yqXtfZ5gG9EG6jkidFyirgAAAA&pid=Api&P=0&h=180',
        },
      ],
        }
    }
}
</script>