<template>
    <div>
      <v-container>
        <v-card class="auth-card d-flex flex-column justify-center align-center pa-5 rounded-xxl" elevation="12">
          <div class="login-content">
            <h1>Welcome to <span class="brand-name">ProfileMatcher</span></h1>
            <p>Your gateway to finding the perfect life partner — 100% Free Matrimony Service!</p>
            <v-divider class="my-4"></v-divider>
            <ul class="features-list">
              <li><v-icon color="pink darken-2" left>mdi-heart</v-icon>Quick and Easy Sign Up</li>
              <li><v-icon color="pink darken-2" left>mdi-account-group</v-icon>Connect with Verified Profiles</li>
              <li><v-icon color="pink darken-2" left>mdi-chat</v-icon>Instant Chat and Secure Messaging</li>
              <li><v-icon color="pink darken-2" left>mdi-shield-check</v-icon>Safe and Privacy-Focused Platform</li>
            </ul>
            <div class="button-group mt-5">
              <v-btn color="pink darken-2" class="action-button ma-2" large @click="onSignUp">Sign Up</v-btn>
              <v-btn outlined color="pink darken-2" class="action-button ma-2" large @click="onLogin">Login</v-btn>
            </div>
          </div>
        </v-card>
      </v-container>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      onSignUp() {
        this.$router.push('/auth');
      },
      onLogin() {
        this.$router.push('/auth');
      },
    },
  };
  </script>
  
  <style scoped>
  .auth-card {
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  .brand-name {
    color: #e91e63;
    font-weight: bold;
  }
  
  .login-content h1 {
    font-size: 2.2rem;
    margin-bottom: 10px;
  }
  
  .login-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .features-list {
    text-align: left;
    list-style: none;
    padding: 0;
  }
  
  .features-list li {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .features-list v-icon {
    margin-right: 8px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .action-button {
    flex: 1;
    border-radius: 30px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .action-button:hover {
    transform: scale(1.08);
  }
  </style>
  