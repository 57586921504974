<template>
    <div>
        <userProfileDesk @getUserProfile="$emit('getUserProfile')"   :userProfile="userDetails" :dropDownList="dropDownList" :clientprofile="true"></userProfileDesk>
        <!-- <userprofileMobile v-else :clientPhotos="clientPhotos" :userDetails="userDetails" @showProfile="showProfile" @showFilterDialog="showFilterDialog"></userprofileMobile> -->
        <!-- <userDetailsDialog :UserDetails="userDetails"   :ShowDetails="ShowDetails" @closeDetails="ShowDetails = false">
        </userDetailsDialog> -->
        <!-- <FilterOptionDialog :filter="filter" :showFilter="showFilter" @closeFilter="closeFilter"></FilterOptionDialog> -->
    </div>
</template>
<script>
import userProfileDesk from './userProfileComponents/userProfileDesk.vue'
// import userprofileMobile from './userProfileComponents/userprofileMobile.vue'
// import userDetailsDialog from './userProfileComponents/userDetailsDialog.vue'
// import FilterOptionDialog from './userProfileComponents/FilterOptionDialog.vue';
export default {
    data() {
        return {
            showFilter:false,
            ShowDetails:false,
            filter: {
                salary: '',
                profilePic: '',
                hobbies: '',
                userName: '',
                age: 0,
                profession: '',
                community: '',
                mobileNo: '',
                caste: '',
                subcaste: '',
                gender: '',
                religion: '',
                clientId: ''
            }
        }
    },
    props:{
        userDetails:{} ,dropDownList:{}
    },
    methods:{
        closeDetails(){
this.ShowDetails = false
        },
        closeFilter(){
this.showFilter = false
        },
        showProfile(){
this.ShowDetails = true
        },
        showFilterDialog(){
            this.showFilter = true

        }

    },
    components: {
        userProfileDesk,
        //  userprofileMobile
        //   userDetailsDialog,
        //    FilterOptionDialog

    }
}
</script>