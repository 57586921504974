<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar fixed dark color="pink" dense class="elevation-0">
                    <v-toolbar-title>client profile</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeProfileView">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <!-- <v-container>
                    <BasicDetails :userDetails="userDetails?.userInfo" :profileDetails="profileDetails?.userInfo"></BasicDetails>
                    <EducationDetails :userDetails="userDetails?.carrers" :profileDetails="profileDetails?.carrers"></EducationDetails>
                    <ReligiousBackground :userDetails="userDetails?.religiousBackGround" :profileDetails="profileDetails?.religiousBackGround"></ReligiousBackground>
                    <Locationdetails :userDetails="userDetails?.locations" :profileDetails="profileDetails?.locations"></Locationdetails>
                </v-container> -->
                <userProfileDesk  :userProfile="userDetails" :profileDetails="profileDetails" :clientprofile="false"></userProfileDesk>

            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import EventServices from '@/services/EventServices';
import userProfileDesk from './userProfileComponents/userProfileDesk.vue'

export default {
  components: {
    userProfileDesk
  },
  data() {
    return {
      dialog: false,
      clientId: localStorage.getItem("clientId"),
      userDetails: {},
      profileDetails: {},
      
    };
  },
  methods: {
    closeProfileView() {
    const query = { ...this.$route.query };
    delete query.showprofile;
    this.$router.replace({ query });
  },
    GetuserProfile(clientId) {
      if (clientId) {
        EventServices.userProfile(clientId)
          .then((response) => {
            if (response.data.status === "S") {
              if (localStorage.getItem("clientId") === clientId) {
                this.userDetails = response.data.clientProfile;
              } else {
                this.profileDetails = response.data.clientProfile;
                this.dialog = true;
              }
            } else {
              this.MessageBar("E", "Unable to find a profile");
            }
          })
          .catch((error) => {
            this.MessageBar("E", error);
          });
      }
    },
  },
  mounted() {
    this.GetuserProfile(this.clientId);
    this.clientId = this.$route.query?.showprofile
    this.GetuserProfile(this.clientId);
  },
};
</script>
