<template>
    <v-container>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
        <v-flex lg12 sm12 xs12 xl12>
            <v-text-field v-model="userDetails.EmailId" outlined dense label="Email Id" :rules="emailRules"
                required></v-text-field>
        </v-flex>
        <v-flex lg12 sm12 xs12 xl12>
            <v-text-field @keypress="onlyForNumber" v-model="userDetails.MobileNo" outlined dense label="MobileNo"
            :rules="mobileNoRules" required></v-text-field>
        </v-flex>
        <v-flex lg12 sm12 xs12 xl12 class="d-flex align-center ">
            <v-icon @click="$emit('Back'),$refs.form.resetValidation()"
                style="cursor: pointer;" dense color="primary" class="float-left">mdi-arrow-left</v-icon>

            <v-btn  @click="$emit('forget')" :disabled="ActivateValidation" dense color="pink" class="action-button ma-2 white--text" outlined large >
                Checkuser
            </v-btn>

        </v-flex>
        </v-form>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            valid:false,
            NewDate:this.maxDate,
            menu:false,
            emailRules: [
        value => !!value || 'Email is required',
        value => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value) || 'Email must be valid'
      ],
      mobileNoRules: [
        value => !!value || 'Mobile number is required',
        value => /^\d{10}$/.test(value) || 'Mobile number must be 10 digits'
      ],
        }
    },
    props: {
        userDetails: {}, ActivateValidation: Boolean,maxDate:String,currentDate:String
    },
    methods:{
        onlyForNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // Allowing backspace and delete keys
      if (keyCode === 8 || keyCode === 46) {
        return;
      }

      // Allowing digits 0-9
      if (keyCode >= 48 && keyCode <= 57) {
        // Allowing input only if the total number of characters is less than or equal to 10
        if ($event.target.value.length >= 10) {
          $event.preventDefault();
        }
      } else {
        $event.preventDefault();
      }
    },
    },
   mounted(){
    this.$refs.form.resetValidation()
   }
}
</script>
<style>
  .action-button {
    flex: 1;
    border-radius: 30px;
    font-weight: bold;
    transition: all 0.3s ease;
  
  }
  
  .action-button:hover {
    transform: scale(1.08);
  }
</style>