<template>
    <div>
        
      <v-card class="rounded-xxl mx-2 mt-2 elevation-3 custom-border"  width="300">
        <v-card-title class="d-flex align-center">
          <v-avatar class="mr-4" color="pink" size="65">
            <v-img contain :src="ProfilePic">
            </v-img>
          </v-avatar>
          <div >
            <div class="ml-3  ">
              {{ ClientName }}
            </div>
            <div class="text-caption ml-3 ">
             <span ><b>clientId:</b></span><span> {{ ClientId }}</span>  
            </div>
            <v-btn 
            small
              class="pink--text "
              text 
              @click="goToProfile">
              Go to Profile
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-card-title>
      </v-card>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProfileCard",
    data(){
      return{
 ClientName:localStorage.getItem("username"),
 ClientId:localStorage.getItem("clientId"),
 ProfilePic:localStorage.getItem("ProfilePic")
      }
    },
    methods: {
      goToProfile() {
        this.$router.push("/userProfile")
      },
    },
  };
  </script>
  
  <style scoped>
 .custom-border {
  border: 8px solid palevioletred;
  border-radius: 24px;
  overflow: hidden;
}
  </style>
  