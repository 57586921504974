<template>
    <div>
<dashboardprofile :Dashboardprofiles="Dashboardprofiles"></dashboardprofile>
    </div>
</template>
<script>
import dashboardprofile from './HomeComponents/dashboardprofile.vue';
import EventServices from '@/services/EventServices';
export default{
    data(){
        return{
clientId:this.$store.state.ClientId,
Dashboardprofiles:{}
        }
    },
    components:{
        dashboardprofile
    },
    methods:{
        GetDashBoardDetails() {
      this.$globalData.overlay = true
      EventServices.GetDashBoardDetails(this.clientId)
        .then((response) => {
          if (response.data.status == "S") {
            this.$globalData.overlay = false
this.Dashboardprofiles = {...response.data}
            // this.$refs.form.resetValidation()
          } else {
            this.loading = false
            this.$globalData.overlay = false
            this.MessageBar("E", response.data.errMsg);
          }
        })
        .catch((error) => {
          this.$globalData.overlay = false
          this.MessageBar("E", error);
        });}
    },
    mounted(){
        this.GetDashBoardDetails()
    }
}
</script>