<template>
  <div>
    <v-container fluid>
      <div class="d-flex ">
        <span class="pink--text font-weight-bold ml-2">{{ DisplayTittle }}</span>
        <v-spacer></v-spacer>
        <v-btn small outlined color="pink" @click="redirectoMatcher">
          view All <v-icon small>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
      <v-row dense class="mt-1">
        <v-slide-group :show-arrows="$vuetify.breakpoint.width > 600">
          <v-slide-item v-for="(client, index) in ClientList" :key="index">
            <v-card class="rounded-xxl ma-2 custom-border" max-width="150" max-height="300">
              <div style="position: relative;">
                <v-img :class="ClientImage ?  '' : 'blurred-img'" :src="client?.profilepic || profilePicture(client.gender)"></v-img>
                <v-overlay v-if="!ClientImage" absolute>
                  <div class="overlay-content">
                    <p>Upload your photo to view others</p>
                  </div>
                </v-overlay>
                <div class="overlay">
                  <div class="text-content">
                    <h3>{{ client.name || 'Client Name Not Available' }}</h3>
                    <!-- <p>{{ truncateText(client.about, 30) }}</p> -->
                  </div>
                </div>
              </div>
              <v-card-actions>
                <v-btn color="pink" text @click="viewClient(client.clientId)">
                  View Profile
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ClientCardView',
  props: {
    ClientList: [],
    Tittle: String
  },
  data(){
    return{
ClientImage: ''
    }
  },
  computed: {
    DisplayTittle() {
      switch (this.Tittle?.toLowerCase()) {
        case "all": return "All Matches";
        case "new": return "New Matches";
        case "nearby": return "Nearby Matches";
        case "shortlisted": return "Shortlisted Matches";
        case "intrested": return "Intrested Matches";
        case "viewby": return "Matches you viewed";
        case "viewedyou": return "Matches viewed you";
        case "hobbie": return "Similar Hobbies";
        case "mutual": return "Mutual Matches";
        default: return '';
      }
    }
  },
  methods: {
    redirectoMatcher(){
this.$router.push("/profileMatcher")
$emit(Tittle)
    },
    viewClient(clientId) {
      const url = `${window.location.origin}${this.$route.path}?showprofile=${encodeURIComponent(clientId)}`;
      window.location.href = url;
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },
    profilePicture(gender) {
      switch (gender) {
        case 'M':
          return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Male.jpg';
        case 'F':
          return 'https://profilematcher.s3.ap-south-1.amazonaws.com/ApplcationImages/Female.jpg';
        default:
          return 'https://via.placeholder.com/300';
      }
    }
  },
  mounted(){
this.ClientImage = localStorage.getItem("ProfilePic")
  },
};
</script>

<style scoped>
.v-card {
  transition: transform 0.3s;
}
.v-card:hover {
  transform: scale(1.05);
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 10px;
  box-sizing: border-box;
}
.overlay-content {
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 10px;
}
.text-content h3 {
  margin: 0;
  font-size: 16px;
}
.text-content p {
  margin: 5px 0 0;
  font-size: 12px;
}
.blurred-img {
  filter: blur(5px);
}
</style>
