<template>
    <v-container>
        <!-- Content for mat or tab format -->
                <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
                <!-- Login form -->
                <v-flex lg12 sm12 xs12 xl12>
                    <v-text-field v-model="userDetails.EmailId" outlined dense label="Email ID" required
                        :rules="emailRules"></v-text-field>
                </v-flex>
                <v-flex lg12 sm12 xs12 xl12>
                    <v-text-field v-model="userDetails.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'" outlined dense label="Password" :rules="passwordRules"
                        required @click:append="showPassword = !showPassword"></v-text-field>
                </v-flex>
                <v-flex lg12 sm12 xs12 xl12>
                    <v-btn @click="$emit('login')"  color="pink" class="action-button ma-2 white--text" outlined :disabled="ActivateValidation" dense >
                        Login
                    </v-btn>
                </v-flex>
                <v-flex class="mt-5">
                  <v-btn color="pink" class="action-button ma-2 white--text" outlined block @click="$emit('forget')" > Forgot Password</v-btn>
                  <v-btn color="pink" class="action-button ma-2 white--text" outlined @click="$emit('signup')" block> sign up</v-btn>
                  <a href="https://forms.gle/BqHHjg1gM5u8PzhT7" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">
  <v-btn
    color="pink"
    class="action-button ma-2 white--text"
    outlined
    block
  >
    Queries & Support
  </v-btn>
</a>
                    <!-- <p @click="$emit('forget')" style="cursor: pointer;" class="primary--text">Forgot Password?</p>
                    <p @click="$emit('signup')" style="cursor: pointer;" class="primary--text">sign up</p>
                    <p  class="primary--text"><a :href="emailLink" style=" text-decoration: none;">Queries & Support</a></p>
                    <p class="primary--text">( demo version free to use)</p>
                    <v-tooltip bottom>
  <template v-slot:activator="{ on, attrs }">
    <v-btn @click="$emit('ReferralForm')" color="pink" outlined v-bind="attrs" v-on="on">
      <v-icon>mdi-currency-inr</v-icon> Refer & Earn
    </v-btn>
  </template>
  <span>Invite friends and earn rewards!</span>
</v-tooltip> -->

                </v-flex>
        </v-form>

    </v-container>

</template>
<script>
export default {
    data() {
        return {
            email: 'profileMatcher2024@gmail.com',
      subject: 'Queries regarding Profile Matching',
      body: 'Dear Team,\n\nI have the following queries:\n\n',
            valid: false,
            emailRules: [
                value => !!value || 'Email is required',
                value => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value) || 'Email must be valid'
            ],
            passwordRules: [
        value => !!value || 'Password is required',
        value => value.length >= 6 || 'Password must be at least 6 characters'
      ],
      showPassword:false
        }
    },
    props: {
        userDetails: {}, ActivateValidation: Boolean
    },
    computed: {
    emailLink() {
      const queryParams = new URLSearchParams({
        subject: this.subject,
        body: this.body
      });
      return `mailto:${this.email}?${queryParams.toString()}`;
    }
  }
}
</script>
<style>
  .action-button {
    flex: 1;
    border-radius: 30px;
    font-weight: bold;
    transition: all 0.3s ease;
  
  }
  
  .action-button:hover {
    transform: scale(1.08);
  }
</style>