<template>
    <div>
        <v-card elevation="0">
            <v-tabs v-model="activeTab" left>
                <v-tab>My Referrals</v-tab>
                <v-tab>Completed Payments</v-tab>
            </v-tabs>
            <v-window v-model="activeTab" class="elevation-0 ">
                <v-window-item v-for="(tab, index) in tabs" :key="index">
                    <v-card v-if="activeTab === index" >
                        <div class="d-flex justify-space-between">
                            <span class="text-subtitle-1 text-capitalize">
                                <v-icon left color="blue darken-4" medium>
                                    {{ currentIcon }} 
                                </v-icon>
                                <span >
                                    {{ currentTitle }} 
                                </span>
                            </span>
                            <span  v-if="activeTab == 0">
                                Here You can Generate a Referal code   <a @click="GenerateCode()" href="javascript:void(0)" role="button" class="text-link"> Generate Code</a>
                            </span>
                            <span  v-if="activeTab == 0 && ReferralCode != ''"  >
                                 Referal code :  {{ ReferralCode }}
                            </span>
                            <span v-if="TotalAmount !=''">
Total Amount: <p class="primary--text"><v-icon small class="teal--text">mdi-currency-inr</v-icon> {{ TotalAmount }} </p>
                            </span>
                            <v-btn class="mb-2"  x-small outlined  color="teal" v-if="activeTab == 0" >
                                Claim 
                            </v-btn>

                        </div>
                        <v-card class="elevation-5">
                            <v-data-table :headers="Headers" :items="currentItems" :items-per-page="10"
                                fixed-header :loading="loading" @dblclick:row="FetchDetail" :search="search">
                            </v-data-table>
                        </v-card>
                    </v-card>
                </v-window-item>
            </v-window>
        </v-card>

    </div>
</template>
<script>
export default {
    data(){
        return{
            TotalAmount:"0",
            ReferralCode:"",
            activeTab:0,
            loading:false,
            tabs: ['My Referrals', 'Completed Payments'],
            titleIcon: ['mdi-account-multiple', 'mdi-cash-multiple'],
            Headers: [
                { text: 'S.No', align: 'start', sortable: false, value: 'id' },
                { text: "ClientName", value: "code" },
                { text: "ClientId", value: "description" },
                { text: "Created Date", value: "createdDate" },
            ],
            details: [],
            search:""
        }
    },
    computed: {
      
        currentItems() {
            return this.activeTab === 0 ? this.Header : this.details;
        },
        currentTitle() {
            return this.tabs[this.activeTab];
        },
        currentIcon() {
            return this.titleIcon[this.activeTab];
        }
    },
    methods:{
        GenerateCode(){

        }
    }

}
</script>